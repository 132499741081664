import React, { Fragment } from 'react'

import {
    PlateDesignSegment,
    PlateDesignContainer,
    MobilePlateDesignSegment,
    MobilePlateDesignContainer
} from './GenericBannerContainer.styles'
import { Responsive } from 'semantic-ui-react'
import theme from '@styles/theme'

interface PlateDesignBannerContainerProps {
    bgLeftColor: string | string[]
    children: object | string
    paddingTop: number
    paddingBottom: number
    mobilePaddingTop: number
    mobilePaddingBottom: number
    darkTheme: boolean
}
const PlateDesignBannerContainer = ({ bgLeftColor, children, paddingTop, paddingBottom, mobilePaddingTop, mobilePaddingBottom, darkTheme }: PlateDesignBannerContainerProps) => {
    return (
        <Fragment>
            <Responsive minWidth={theme.responsive.desktop.minWidth}>
                <PlateDesignSegment basic darkTheme={darkTheme} bgLeftColor={darkTheme ? theme.brand.colors.black : bgLeftColor} paddingTop={paddingTop} paddingBottom={paddingBottom} >
                    <PlateDesignContainer>
                        {children}
                    </PlateDesignContainer>
                </PlateDesignSegment>
            </Responsive>
            <Responsive darkTheme={darkTheme} bgLeftColor={darkTheme ? theme.brand.colors.black : bgLeftColor}  maxWidth={theme.responsive.tablet.maxWidth}>
                <MobilePlateDesignSegment basic paddingBottom={mobilePaddingBottom} darkTheme={darkTheme} >
                    <MobilePlateDesignContainer>
                        {children}
                    </MobilePlateDesignContainer>
                </MobilePlateDesignSegment>
            </Responsive>
        </Fragment>
    )

}

PlateDesignBannerContainer.defaultProps = {
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    darkTheme: false
}
export default PlateDesignBannerContainer;