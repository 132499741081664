import React, { Fragment } from 'react'

import {
    PlateDesignContainer,
    MobilePlateDesignSegment,
    MobilePlateDesignContainer,
    UserAccountWrapperSegment
} from './GenericBannerContainer.styles'
import { Responsive } from 'semantic-ui-react'
import theme from '@styles/theme'

interface UserAccountWrapperContainerProps {
    bgLeftColor: string | string[]
    children: object | string
    paddingTop: number
    paddingBottom: number
    mobilePaddingTop: number
    mobilePaddingBottom: number
}
const UserAccountWrapperContainer = ({ bgLeftColor, children, paddingTop, paddingBottom, mobilePaddingTop, mobilePaddingBottom }: UserAccountWrapperContainerProps) => {
    return (
        <Fragment>
            <Responsive minWidth={theme.responsive.desktop.minWidth}>
                <UserAccountWrapperSegment basic bgLeftColor={bgLeftColor} paddingTop={paddingTop} paddingBottom={paddingBottom} >
                    <PlateDesignContainer>
                        {children}
                    </PlateDesignContainer>
                </UserAccountWrapperSegment>
            </Responsive>
            <Responsive maxWidth={theme.responsive.tablet.maxWidth}>
                <MobilePlateDesignSegment basic paddingBottom={mobilePaddingBottom} >
                    <MobilePlateDesignContainer>
                        {children}
                    </MobilePlateDesignContainer>
                </MobilePlateDesignSegment>
            </Responsive>
        </Fragment>
    )

}

UserAccountWrapperContainer.defaultProps = {
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0
}
export default UserAccountWrapperContainer;