import React from 'react'

import {
    GenericContainer,
    GenericSegment
} from './GenericBannerContainer.styles'
import { Container } from 'semantic-ui-react'


interface GenericBannerContainerProps{
    backgroundColor: string | string[]
    children: object | string
    padding: {
        mobile: {
            top: number,
            bottom: number
        },
        desktop: {
            top: number,
            bottom: number
        }
    }
    removeContainer?: boolean
    containerMaxWidth?: number
}

const GenericBannerContainer = ({backgroundColor, children, padding, removeContainer, containerMaxWidth}: GenericBannerContainerProps)=>{
    return <GenericSegment basic bgColor={backgroundColor} padding={padding} >
        {
            removeContainer && children
        }
        {
            !removeContainer &&
            <GenericContainer maxWidth={containerMaxWidth}>
                {children}
            </GenericContainer>
        }
    </GenericSegment>
}

GenericBannerContainer.defaultProps={
    backgroundColor: "#FFFFFF",
    paddingTop: 0,
    paddingBottom: 0,
    containerMaxWidth: '100%'
}

export default GenericBannerContainer;